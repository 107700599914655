<template>
  <div class="content lg flex-box">
    <data-list
      ref="dataList"
      class="flex-grow"
      url="/admin/fs-tablet"
      entity-name="牌位"
      query="&expand=fs&sort=-id"
      hide-edit
      hide-show-detail
      hide-delete
      :row-selection="{type: 'checkbox', selectedRowKeys: selectKeys, onChange: handleSelectChange}"
      :action="action"
      :columns="columns"
      :deal-list="dealList"
      :custom-url="customUrl"
      :request-config="{noTempleFilter: true}"
      :search-key-type="{name: 1}">
      <template v-slot:button>
        <a-button type="primary" @click="printSelect">打印牌位</a-button>
      </template>
      <template v-slot:action="{item}">
        <div class="row-btn">
          <span class="txt-btn" @click.stop="previewTablet(item)">预览</span>
          <span class="txt-btn" @click.stop="printTablet(item)">打印</span>
        </div>
      </template>
      <template v-slot:query="{form}">
        <a-form-model-item label="">
          <a-select
            style="width: 180px"
            placeholder="选择类型筛选"
            @change="handleTypeChange"
            :options="typeList"
            v-model="form.fs_type">
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="" v-show="subTypeList.length > 0">
          <a-select
            style="width: 180px"
            placeholder="选择种类筛选"
            :options="subTypeList"
            @change="updateList"
            v-model="form.size">
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="佛事日期">
          <a-range-picker
            v-model="form.time"
            :placeholder="['开始时间', '结束时间']"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @change="updateList"/>
        </a-form-model-item>
        <a-form-model-item label="">
          <a-input v-model="form.num" placeholder="输入牌位编号查询"></a-input>
        </a-form-model-item>
      </template>
    </data-list>
    <div class="buddha-tablet-back" v-show="tv" @click="tv=false">
      <tablet-print ref="tabletPrint" :list="tablets"></tablet-print>
    </div>
  </div>
</template>

<script>
    import {getBuddhaSubTypeName, getBuddhaTypeConfig, buddhaTypeList} from "../../common/buddha/type";
    import {printDom} from "../../common/js/tool";
    import TabletPrint from "../../layouts/TabletPrint";
    import {getTemple} from "../../common/js/storage";

    export default {
        name: "TabletBlacklist",
        components: {TabletPrint},
        data() {
            return {
                typeList: [{key: 0, title: '全部'}],
                subTypeList: [],
                columns: [
                    {title: '牌位编号', dataIndex: 'num'},
                    {title: '类别', dataIndex: 'tempName'},
                    {title: '种类', dataIndex: 'subName'},
                    {title: '佛事日期', dataIndex: 'fsDate'},
                ],
                selectKeys: [],
                selectItems: [],
                tablets: [],
                tv: false
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            },
        },
        created() {
            this.setOptions();
        },
        methods: {
            setOptions() {
                let typeList = buddhaTypeList.filter(item => item.key >= 13);
                this.typeList = [{key: 0, title: '全部'}].concat(typeList.map(item => {return {key: item.key, title: item.title}}));
            },
            handleTypeChange(e) {
                if(e > 0) {
                    this.subTypeList = [{key: "0", title: '全部'}].concat(getBuddhaTypeConfig(e).subTypes);
                } else {
                    this.subTypeList = [];
                }
                this.$set(this.$refs.dataList.form, 'type', undefined);
                this.updateList();
            },
            handleSelectChange(keys, rows) {
                this.selectKeys = rows.map(item => item.id);
                this.selectItems = rows;
            },
            customUrl(form) {
                let res = `&temple_id=${getTemple()}`;
                if(form.fs_type > 0) {
                    res += `&filter[fs_type]=${form.fs_type}`;
                } else {
                    res += "&filter[fs_type][gte]=13";
                }
                if(form.size > 0) {
                    res += `&filter[size]=${form.size}`;
                }
                if(form.time && form.time.length == 2) {
                    let start = `${form.time[0]} 00:00:00`;
                    let end =`${form.time[1]} 23:59:59`;
                    res += `&start_time=${start}&end_time=${end}`;
                }
                if(form.num) {
                    res += `&filter[num][like]=${form.num}`
                }
                return res;
            },
            updateList() {
                this.$refs.dataList.getList();
            },
            dealList(list, fn) {
                fn(list.map(item => {
                    let res = {
                        ...item,
                        temp: item.fs.temp,
                        typeName: item.cat == 1 ? '长生' : '往生',
                        fsDate: item.fs && item.fs.date || '--',
                        tempName: item.fs && item.fs.temp ? item.fs.temp.name : '--',
                        subName: item.fs && item.fs.temp ? getBuddhaSubTypeName(item.fs.temp.fs_type, item.size) : '--'
                    };
                    try {
                        let info = JSON.parse(item.info);
                        res = {...res, ...info};
                    } catch (e) {
                        console.error(e);
                    }
                    return res;
                }))
            },
            printTablets() {
                let style = document.createElement('style');
                style.setAttribute("type", 'text/css');
                style.setAttribute("meta", "print");
                let css = '@page { margin: 0; padding: 0; border: 0; size: A4 landscape; }';
                style.appendChild(document.createTextNode(css));
                printDom(this.$refs.tabletPrint.$el, style);
            },
            printSelect() {
                if(this.selectItems.length > 0) {
                    this.tablets = this.selectItems;
                    this.$nextTick(() => {
                        this.printTablets();
                    })
                } else {
                    this.$message.warning("请选择要打印的牌位");
                }
            },
            printTablet(item) {
                this.tablets = [item];
                this.$nextTick(() => {
                    this.printTablets();
                })
            },
            previewTablet(item) {
                this.tablets = [item];
                this.tv = true;
            }
        }
    }
</script>

<style scoped lang="less">
  .buddha-tablet-back {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @black;
    z-index: 100;
    overflow: auto;
  }
</style>
